
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { RouteName } from '../router/RouteName'
import Spinner from '../components/reusable/BaseSpinner.vue'
import { db, functions } from '@/firebase'
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { MagentrixConfig } from 'types/config'
import { FunctionNames } from '@/enums/FunctionNames'

export default defineComponent({
  components: {
    Spinner,
  },
  setup() {
    const routeName = RouteName
    const loading = ref(true)
    const magentrixError = ref('')
    const magentrixMessage = ref('')
    const magentrixLoading = ref(false)

    const magentrixForm = reactive({
      magentrixUsername: '',
      magentrixPassword: '',
      magentrixCreateSessionUrl: '',
      magentrixQueryUrl: '',
    })

    db.collection(FirestoreColNames.APP_CONFIG)
      .doc('magentrix')
      .get()
      .then(doc => {
        const data = doc.data() as MagentrixConfig
        magentrixForm.magentrixUsername = data.UserName
        magentrixForm.magentrixPassword = data.Password
        magentrixForm.magentrixCreateSessionUrl = data.createSessionUrl
        magentrixForm.magentrixQueryUrl = data.queryUrl
        loading.value = false
      })

    const saveMagentrix = async () => {
      magentrixLoading.value = true
      try {
        const payload: MagentrixConfig = {
          UserName: magentrixForm.magentrixUsername,
          Password: magentrixForm.magentrixPassword,
          createSessionUrl: magentrixForm.magentrixCreateSessionUrl,
          queryUrl: magentrixForm.magentrixQueryUrl,
        }
        const { data: isValid } = await functions.httpsCallable(
          FunctionNames.CHECK_FOR_VALID_MAGENTRIX_CREDS
        )(payload)

        if (isValid) {
          // Proceed to save
          await db
            .collection(FirestoreColNames.APP_CONFIG)
            .doc('magentrix')
            .set(payload, { merge: true })
          magentrixError.value = ''
          magentrixMessage.value =
            'Saved successful and credentials are working.'
        } else {
          magentrixError.value =
            'Failed to access the endpoint using the provided credentials. Synchronization will fail.'
        }
      } catch (error) {
        magentrixError.value = error
      }
      magentrixLoading.value = false
    }

    return {
      ...toRefs(magentrixForm),
      routeName,
      loading,
      saveMagentrix,
      magentrixError,
      magentrixLoading,
      magentrixMessage,
    }
  },
})
