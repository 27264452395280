<template>
  <div class="max-w-6xl mx-auto py-6 px-3 sm:px-6 lg:px-8">
    <div class="flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="overflow-hidden border border-gray-300 bg-gray-200 sm:rounded-lg"
          >
            <div
              v-if="loading"
              class="min-w-full h-48 flex justify-center items-center"
            >
              <spinner class="border-brand" />
            </div>
            <div v-else class="text-gray-700 p-8">
              <h3 class="font-semibold text-xl">Magentrix Endpoint API</h3>
              <div class="my-4 flex space-x-6 items-center p-4">
                <label for="magentrixUsername" class="text-sm w-32"
                  >Username</label
                >
                <input
                  type="text"
                  name="magentrixUsername"
                  id="magentrixUsername"
                  v-model="magentrixUsername"
                  autocomplete="off"
                  spellcheck="false"
                  class="border rounded-md px-4 py-2 text-sm outline-none focus:border-brand w-1/3"
                />
              </div>
              <div class="my-4 flex space-x-6 items-center p-4">
                <label for="magentrixUsername" class="text-sm w-32"
                  >Password</label
                >
                <input
                  type="text"
                  name="magentrixPassword"
                  id="magentrixPassword"
                  v-model="magentrixPassword"
                  autocomplete="off"
                  spellcheck="false"
                  class="border rounded-md px-4 py-2 text-sm outline-none focus:border-brand w-1/3"
                />
              </div>
              <div class="my-4 flex space-x-6 items-center p-4">
                <label for="magentrixCreateSessionUrl" class="text-sm w-32"
                  >Create Session URL</label
                >
                <input
                  type="text"
                  name="magentrixCreateSessionUrl"
                  id="magentrixCreateSessionUrl"
                  v-model="magentrixCreateSessionUrl"
                  autocomplete="off"
                  spellcheck="false"
                  class="border rounded-md px-4 py-2 text-sm outline-none focus:border-brand w-3/4"
                />
              </div>
              <div class="my-4 flex space-x-6 items-center p-4">
                <label for="magentrixQueryUrl" class="text-sm w-32"
                  >Query URL</label
                >
                <input
                  type="text"
                  name="magentrixQueryUrl"
                  id="magentrixQueryUrl"
                  v-model="magentrixQueryUrl"
                  autocomplete="off"
                  spellcheck="false"
                  class="border rounded-md px-4 py-2 text-sm outline-none focus:border-brand w-3/4"
                />
              </div>
              <p
                v-if="magentrixError"
                class="float-left mt-10 text-sm font-semibold text-red-600 ml-4"
              >
                {{ magentrixError }}
              </p>
              <p
                v-if="magentrixMessage"
                class="float-left mt-10 text-sm font-semibold text-red-600 ml-4"
              >
                {{ magentrixError }}
              </p>
              <button
                class="my-10 mr-10 w-32 flex justify-center float-right py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-500 hover:bg-blue-400 focus:outline-none transition duration-150 ease-in-out disabled:opacity-50 disabled:text-gray-500"
                @click="saveMagentrix"
              >
                <div v-if="magentrixLoading" class="loader" />
                <p v-else>Save</p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { RouteName } from '../router/RouteName'
import Spinner from '../components/reusable/BaseSpinner.vue'
import { db, functions } from '@/firebase'
import { FirestoreColNames } from '@/enums/FirestoreColNames'
import { MagentrixConfig } from 'types/config'
import { FunctionNames } from '@/enums/FunctionNames'

export default defineComponent({
  components: {
    Spinner,
  },
  setup() {
    const routeName = RouteName
    const loading = ref(true)
    const magentrixError = ref('')
    const magentrixMessage = ref('')
    const magentrixLoading = ref(false)

    const magentrixForm = reactive({
      magentrixUsername: '',
      magentrixPassword: '',
      magentrixCreateSessionUrl: '',
      magentrixQueryUrl: '',
    })

    db.collection(FirestoreColNames.APP_CONFIG)
      .doc('magentrix')
      .get()
      .then(doc => {
        const data = doc.data() as MagentrixConfig
        magentrixForm.magentrixUsername = data.UserName
        magentrixForm.magentrixPassword = data.Password
        magentrixForm.magentrixCreateSessionUrl = data.createSessionUrl
        magentrixForm.magentrixQueryUrl = data.queryUrl
        loading.value = false
      })

    const saveMagentrix = async () => {
      magentrixLoading.value = true
      try {
        const payload: MagentrixConfig = {
          UserName: magentrixForm.magentrixUsername,
          Password: magentrixForm.magentrixPassword,
          createSessionUrl: magentrixForm.magentrixCreateSessionUrl,
          queryUrl: magentrixForm.magentrixQueryUrl,
        }
        const { data: isValid } = await functions.httpsCallable(
          FunctionNames.CHECK_FOR_VALID_MAGENTRIX_CREDS
        )(payload)

        if (isValid) {
          // Proceed to save
          await db
            .collection(FirestoreColNames.APP_CONFIG)
            .doc('magentrix')
            .set(payload, { merge: true })
          magentrixError.value = ''
          magentrixMessage.value =
            'Saved successful and credentials are working.'
        } else {
          magentrixError.value =
            'Failed to access the endpoint using the provided credentials. Synchronization will fail.'
        }
      } catch (error) {
        magentrixError.value = error
      }
      magentrixLoading.value = false
    }

    return {
      ...toRefs(magentrixForm),
      routeName,
      loading,
      saveMagentrix,
      magentrixError,
      magentrixLoading,
      magentrixMessage,
    }
  },
})
</script>

<style scoped>
.loader {
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
