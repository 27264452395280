export const enum FunctionNames {
  ADMIN_LIST_ALL_USERS = 'admin-listAllUsers',
  ADMIN_CREATE_NEW_USER = 'admin-createNewUser',
  ADMIN_EDIT_USER = 'admin-editUser',
  ADMIN_DELETE_USER = 'admin-deleteUser',
  ADMIN_CLEAR_CACHES = 'admin-clearCaches',
  MANUAL_FORCE_SYNC = 'scheduled-manualTaskDivider',
  CHECK_FOR_VALID_HLAPIKEY = 'highlevel-checkForValidHlApiKey',
  CHECK_FOR_VALID_MAGENTRIX_CREDS = 'magentrix-checkForValidMagentrixCreds',
  CHECK_FOR_DUPLICATED_LOCATION_ID = 'highlevel-checkForDuplicatedLocationId',
  FORCE_INDIVIDUAL_SYNC = 'scheduled-forceIndividualSync',
  CLEAR_FAILURE_LOGS = 'admin-clearFailureLogs',
}
